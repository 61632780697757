<template>
  <b-row>
    <b-col md="12">
      <order-spare-parts />
      <template v-if="interview.id_com_interview_type === 2">
        <interview-summary-service />
        <interview-lines-service />
      </template>
      <template v-else>
        <interview-summary-spare-parts />
        <interview-lines-spare-parts />
      </template>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import OrderSpareParts from '@/views/Spareparts/OrderSpareParts/OrderSpareParts.vue'
import InterviewSummaryService from '@/views/Interviews/components/view/ServiceMechanical/InterviewSummary.vue'
import InterviewSummarySpareParts from '@/views/Interviews/components/view/SpareParts/InterviewSummary.vue'
import InterviewLinesService from '@/views/Interviews/components/view/ServiceMechanical/InterviewLines.vue'
import InterviewLinesSpareParts from '@/views/Interviews/components/view/SpareParts/InterviewLines.vue'

export default {
  name: 'Index',
  components: {
    BRow,
    BCol,
    OrderSpareParts,
    InterviewSummaryService,
    InterviewSummarySpareParts,
    InterviewLinesService,
    InterviewLinesSpareParts,
  },
  data() {
    return {
      dataQuery: {
        select: [
          'com_sparepart_order.id AS id',
          'com_sparepart_order.interview_number AS interview_number',
          'com_sparepart_order_type.title AS sparepart_order_type',
          'com_sparepart_order.spare_code AS spare_code',
          'com_sparepart_order.piece AS piece',
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_user.name AS username',
          'com_brand.name AS brand',
          'com_location.title AS location',
          'com_sparepart_order.adddate AS adddate',
          'com_sparepart_order.odate AS odate',
          'com_sparepart_order.adate AS adate',
          'com_sparepart_order.price AS price',
          'com_sparepart_order.ddate AS ddate',
          'com_sparepart_order.adate AS adate',
          'com_sparepart_order_status.title AS status',
          'users.name AS last_user',
          'com_cars.license_plate AS license_plate',
        ],
        where: {
          'com_sparepart_order.id_com_sparepart_order_status': 1,
        },
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['sparePartOrders/dataItem']
    },
    interview() {
      return this.$store.getters['interviews/getViewInterview']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getDataList()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getDataItem()
    this.getInterview()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getDataItem() {
      this.$store.dispatch('sparePartOrders/getDataItem', this.$route.params.interview_number)
    },
    getInterview() {
      this.$store.dispatch('interviews/interviewView', this.$route.params.interview_number)
    },
  },
}
</script>
