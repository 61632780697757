<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Parça Siparişleri</b-card-title>
      </b-card-header>
      <b-table-simple
        small
        reponsive="sm"
      >
        <b-thead>
          <b-tr>
            <b-th>Sipariş Özeti</b-th>
            <b-th>Parça Kodu</b-th>
            <b-th>Adet</b-th>
            <b-th>Birim Maliyet (KDV Hariç)</b-th>
            <b-th>Tahmini Geliş Tarihi</b-th>
            <b-th class="text-center">
              <FeatherIcon icon="TrashIcon" />
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(order,key) in orders">
            <b-tr :key="key">
              <b-td rowspan="2">
                <div class="font-weight-bold font-small-2 text-danger">
                  {{ order.sparepart_order_type }}
                </div>
                <div class="font-weight-bold font-small-2 text-muted">
                  {{ order.customer_name }}
                </div>
                <div class="font-weight-bold font-small-2 text-info">
                  {{ order.username }}
                </div>
                <div class="font-weight-bold font-small-2 text-warning">
                  {{ moment(order.adddate).format('ll') }}
                </div>
              </b-td>
              <b-td>
                <b-form-input
                  v-model="order.spare_code"
                  placeholder="Parça Kodu"
                />
              </b-td>
              <b-td>
                <b-form-input
                  v-model="order.piece"
                  type="number"
                  placeholder="Adet"
                />
              </b-td>
              <b-td>
                <b-input-group>
                  <cleave
                    id="price"
                    v-model="order.price"
                    class="form-control"
                    :options="options.price"
                    :raw="true"
                    placeholder="Birim Maliyet"
                  />
                  <b-input-group-append is-text>
                    ₺
                  </b-input-group-append>
                </b-input-group>
              </b-td>
              <b-td>
                <b-form-datepicker
                  :id="'dline_' + key"
                  v-model="order.dline"
                  v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                  locale="tr"
                  start-weekday="1"
                />
              </b-td>
              <b-td
                rowspan="2"
                class="text-center"
              >
                <b-button
                  variant="flat-danger"
                  size="sm"
                  pill
                  class="btn-icon"
                  @click="removeLine(order.id)"
                >
                  <FeatherIcon icon="TrashIcon" />
                </b-button>
              </b-td>
            </b-tr>
            <b-tr :key="'sub_' + key">
              <b-td colspan="4">
                <v-select
                  v-model="order.id_com_sparepart_order_status"
                  :options="statuses"
                  label="title"
                  :reduce="item => item.id"
                  placeholder="Durum"
                />
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td
              colspan="6"
            >
              <div class="d-flex justify-content-between">
                <div>
                  <b-button
                    variant="primary"
                    @click="addNewLine = !addNewLine"
                  >
                    <FeatherIcon icon="PlusIcon" /> Parça Ekle
                  </b-button>
                </div>
                <div>
                  <b-button
                    variant="danger"
                    @click="updateOrders"
                  >
                    <FeatherIcon icon="SaveIcon" /> Güncelle
                  </b-button>
                </div>
              </div>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-card>
    <b-card
      v-if="addNewLine"
      title="Yeni Parça Ekle"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Sipariş Tipi"
            label-for="id_com_sparepart_order_type"
          >
            <v-select
              id="id_com_sparepart_order_type"
              v-model="newLine.id_com_sparepart_order_type"
              placeholder="Sipariş Tipi"
              :options="types"
              label="title"
              :reduce="item => item.id"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-for="spare_code"
            label="Parça Kodu"
          >
            <b-form-input
              id="spare_code"
              v-model="newLine.spare_code"
              placeholder="Parça Kodu"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Adet"
            label-for="piece"
          >
            <b-form-input
              id="piece"
              v-model="newLine.piece"
              type="number"
              placeholder="Adet"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Birim Maliyet (KDV Hariç)"
            label-for="price_new"
          >
            <b-input-group>
              <cleave
                id="price_new"
                v-model="newLine.price"
                class="form-control"
                :options="options.price"
                :raw="true"
                placeholder="Birim Maliyet"
              />
              <b-input-group-append is-text>
                ₺
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Sipariş Durumu"
            label-for="id_com_sparepart_order_status"
          >
            <v-select
              id="id_com_sparepart_order_status"
              v-model="newLine.id_com_sparepart_order_status"
              :options="statuses"
              label="title"
              :reduce="item => item.id"
              placeholder="Sipariş Durumu"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Tahmini Geliş Tarihi"
            label-for="dline"
          >
            <b-form-datepicker
              id="dline"
              v-model="newLine.dline"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            variant="danger"
            @click="insertOrder"
          >
            <FeatherIcon icon="SaveIcon" />
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BButton,
  BTableSimple,
  BThead,
  BTh,
  BTr,
  BTd,
  BTbody,
  BTfoot,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

export default {
  name: 'OrderSpareParts',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BTableSimple,
    BThead,
    BTh,
    BTr,
    BTd,
    BTbody,
    BTfoot,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    Cleave,
  },
  data() {
    return {
      addNewLine: false,
      newLine: {
        interview_number: this.$route.params.interview_number,
        spare_code: null,
        piece: null,
        price: null,
        dline: null,
        id_com_sparepart_order_type: null,
        id_com_sparepart_order_status: null,
      },
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    orders() {
      return this.$store.getters['sparePartOrders/dataList']
    },
    statuses() {
      return this.$store.getters['sparepartOrderStatus/dataList']
    },
    types() {
      return this.$store.getters['sparepartOrderTypes/getSparepart_order_types']
    },
  },
  created() {
    this.getOrders()
    this.getOrderStatuses()
    this.getOrderTypes()
  },
  methods: {
    updateOrders() {
      this.$store.dispatch('sparePartOrders/saveData', this.orders)
    },
    insertOrder() {
      this.$store.dispatch('sparePartOrders/saveData', this.newLine)
        .then(res => {
          if (res) {
            this.addNewLine = false
            this.getOrders()
          }
        })
    },
    removeLine(id) {
      this.$store.dispatch('sparePartOrders/deleteData', id)
        .then(res => {
          if (res) {
            this.getOrders()
          }
        })
    },
    getOrders() {
      this.$store.dispatch('sparePartOrders/getDataList', {
        where: {
          'com_sparepart_order.interview_number': this.$route.params.interview_number,
        },
        order_by: ['com_sparepart_order.id', 'ASC'],
      })
    },
    getOrderStatuses() {
      this.$store.dispatch('sparepartOrderStatus/getDataList', {
        select: ['com_sparepart_order_status.id AS id', 'com_sparepart_order_status.title AS title'],
      })
    },
    getOrderTypes() {
      this.$store.dispatch('sparepartOrderTypes/sparepart_order_typesList', {
        select: ['com_sparepart_order_type.id AS id', 'com_sparepart_order_type.title AS title'],
      })
    },
  },
}
</script>
